import React from "react";
import { motion } from "framer-motion";
import { navigation } from "./config";
import NavItem from "./NavItem";
import whatsapp from "../../img/icons/whatsapp-icon.svg";
import instagram from "../../img/icons/instagram-icon.svg";

const Navigation = ({ open, toggleMenu }) => {
  return (
    <>
      <motion.div className={open ? "menu-mob active" : "menu-mob"}>
        <nav>
          <motion.ul>
            {navigation.map((item, i) => (
              <NavItem
                link={item.link}
                text={item.text}
                key={item.id}
                toggleMenu={toggleMenu}
                idx={i}
                open={open}
              />
            ))}
          </motion.ul>
        </nav>
        <motion.div className="social">
          <a href="https://wa.me/380674459055" target="_blank" rel="noreferrer">
            <img src={whatsapp} alt="" />
          </a>

          <a
            href="https://www.instagram.com/wedlifer/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Navigation;
