import React, { useEffect, useState, useRef, Suspense, lazy } from "react";
import { NavLink } from "react-router-dom";
import "./projects.css";
import { projects } from "./config";
import { posters } from "./config";
import Nav from "../../components/nav/Nav";

import transition from "../../transition";
import ProjectItem from "./ProjectItem";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
import Instagram from "../../components/instagram/Instagram";
import { useOnLoadImages } from "../../components/hooks/useOnLoadImages";
import LoaderLight from "../../components/loaderLight/LoaderLight";
import useWindowDimensions from "../../components/hooks/useWindowDimensions";
import ParallaxText from "../../components/parallaxText/ParallaxText";
import Loader1 from "../../components/loader/Loader1";

const Projects = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { height, width } = useWindowDimensions();
  const sizeMob = width < 1024;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.sessionStorage.getItem("loader")) {
      setIsLoading(false);
    }
    console.log();
  }, []);

  const navLogoPosition = {
    left: "30px",
    transform: "translateX(0)",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="projects">
      {isLoading && <Loader1 />}

      {/* {isLoading && <LoaderLight />} */}
      <>
        <Nav position={navLogoPosition} />
      </>
      {sizeMob ? (
        <ul className="projects__list">
          {posters?.map((item, i) => (
            <li key={item.id}>
              <NavLink to={`/project/${item.urlName}`}>
                <div>
                  <div>
                    <img
                      src={item.imgPrev}
                      id={item.id}
                      alt=""
                      className="projects__img"
                    />
                  </div>
                </div>
                <div className="projects__item-info">
                  <span className="projects__item-title">{item.title}</span>
                  <span className="projects__item-location">
                    {item.location}
                  </span>
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      ) : (
        <ul className="projects__list">
          {projects &&
            projects.map((item, i) => (
              <li key={item.id}>
                <NavLink to={`/project/${item.urlName}`}>
                  <div>
                    <ProjectItem item={item} />
                  </div>
                  <div className="projects__item-info">
                    <span className="projects__item-title">{item.title}</span>
                    <span className="projects__item-location">
                      {item.location}
                    </span>
                  </div>
                </NavLink>
              </li>
            ))}
        </ul>
      )}

      <VideoPlayer />
      <section className="parallaxText">
        <ParallaxText baseVelocity={-1}>
          OUR INSTAGRAM OUR INSTAGRAM OUR INSTAGRAM
        </ParallaxText>
        <ParallaxText baseVelocity={1}>
          OUR INSTAGRAM OUR INSTAGRAM OUR INSTAGRAM
        </ParallaxText>
      </section>
      <Instagram />
    </div>
  );
};

export default transition(Projects, 0);
