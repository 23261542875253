import React from "react";
import Nav from "../../components/nav/Nav";
import { delay, motion } from "framer-motion";
import img from "../../img/about/main.jpg";

import "./about.css";

const titleVariant = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 2,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};
const imgVariant = {
  initial: { x: -20, opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 2,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

const textVariant = {
  initial: { x: 20, opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};
const textVariant2 = {
  initial: { x: 20, opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.6, -0.05, 0.01, 0.99],
      delay: 0.1,
    },
  },
};
const textVariant3 = {
  initial: { x: 20, opacity: 0 },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.6, -0.05, 0.01, 0.99],
      delay: 0.2,
    },
  },
};

const About = () => {
  const navLogoPosition = {
    left: "30px",
    transform: "translateX(0)",
  };
  return (
    <div className="about">
      <Nav position={navLogoPosition} />
      <div className="page">
        <div className="about-hero">
          <div className="about-title">
            <motion.h1
              variants={titleVariant}
              initial="initial"
              animate="animate"
            >
              About us
            </motion.h1>
          </div>
        </div>
        <div className="about-content">
          <div className="about-img-wrapper">
            <motion.img
              src={img}
              alt=""
              variants={imgVariant}
              initial="initial"
              animate="animate"
            />
          </div>
          <div>
            <motion.p
              variants={textVariant}
              initial="initial"
              animate="animate"
            >
              Hello and warm greetings from Lake Como! My Wife and I are a
              dynamic duo of photographers who call this picturesque setting our
              home. With a combined experience of 14 years in the industry, we
              have had the privilege of capturing over 300 weddings, ranging
              from grand-scale events to intimate ceremonies.
            </motion.p>
            <motion.p
              variants={textVariant2}
              initial="initial"
              animate="animate"
            >
              Our photography style seamlessly blends the sophistication of a
              magazine editorial with the cinematic allure of storytelling,
              ensuring that every moment is beautifully immortalized. Carrying
              two cameras with different lenses, we are always ready to adapt to
              the unique atmosphere of each wedding, capturing both the
              intricate details and the heartfelt emotions that make your day
              truly special.
            </motion.p>
            <motion.p
              variants={textVariant3}
              initial="initial"
              animate="animate"
            >
              Photography is not just a job for us, it is a deep-rooted passion
              that drives us to create timeless memories for our clients. We
              would be thrilled to hear more about your wedding vision and
              collaborate with you to craft a collection of photographs that
              perfectly reflect your love story. Feel free to share your
              thoughts and ask any questions you may have — let’s make your
              wedding day an unforgettable masterpiece.
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
