import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../components/hooks/useWindowDimensions";
import Nav from "../../components/nav/Nav";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { gallery } from "./config";
import transition from "../../transition";
import "./gallery.css";
import SwiperMobGallery from "../../components/swiper/SwiperMobGallery";
import LoaderLight from "../../components/loaderLight/LoaderLight";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import InfiniteScroll from "react-infinite-scroll-component";
import aspectRatioImg from "../../components/functions/aspectRatioImg";
import { AsyncImage } from "loadable-image";
// import { Blur } from "transitions-kit";
import { useOnLoadImages } from "../../components/hooks/useOnLoadImages";

const Gallery = () => {
  const navLogoPosition = {
    left: "30px",
    transform: "translateX(0)",
  };
  let [fullWidth, setFullWidth] = useState(false);
  const toggleFullWidth = () => setFullWidth((value) => !value);
  const { height, width } = useWindowDimensions();
  const sizeMob = width < 1024;
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  const [items, setItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const itemsPerPage = 8; // Adjust based on your needs
  useEffect(() => {
    const fetchData = () => {
      const response = gallery;
      setItems(response);
      setDisplayedItems(response.slice(0, itemsPerPage));
    };
    fetchData();
  }, []);
  const fetchMoreData = () => {
    if (displayedItems.length >= items.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setDisplayedItems((prevItems) => [
        ...prevItems,
        ...items.slice(prevItems.length, prevItems.length + itemsPerPage),
      ]);
      setPage((prevPage) => prevPage + 1);
    }, 500);
  };

  const wrapperRef = useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  return (
    <div>
      {/* {isLoading && <LoaderLight />} */}
      <div>
        <Nav position={navLogoPosition} />
        {!sizeMob ? (
          <InfiniteScroll
            hasMore={true}
            dataLength={displayedItems.length}
            next={fetchMoreData}
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 1024: 1, 1025: 3, 1100: 4 }}
            >
              <Masonry className="masonry-wrap" gutter="20px">
                {displayedItems &&
                  displayedItems.map((img) => (
                    <div key={img.id} className="masonry-item" ref={wrapperRef}>
                      <div style={{ background: "#eee" }}>
                        <LazyLoadImage
                          src={img.src}
                          alt=""
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                          effect="blur"
                          threshold={100}
                        />
                      </div>
                    </div>
                  ))}
              </Masonry>
            </ResponsiveMasonry>
          </InfiniteScroll>
        ) : (
          <SwiperMobGallery
            imgListSrc={gallery}
            fullWidth={fullWidth}
            toggleFullWidth={toggleFullWidth}
          />
        )}
      </div>
    </div>
  );
};

export default transition(Gallery, 0);
