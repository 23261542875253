import React, { useRef } from "react";
import { motion } from "framer-motion";

const titleVariant = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 2,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

const ProjectDetailsHero = ({
  refScrl,
  scrollYProgress,
  location,
  title,
  imgPrev,
  handleClickScroll,
  sizeMob,
}) => {
  return (
    <motion.div
      ref={refScrl}
      className="project-details__text-container"
      style={
        !sizeMob && {
          opacity: scrollYProgress,
        }
      }
    >
      <div className="project-details__text-wrap">
        <motion.div
          className="project-details__text"
          variants={titleVariant}
          initial="initial"
          animate="animate"
        >
          <div className="project-details__location">{location}</div>
          <div className="project-details__title">{title}</div>
        </motion.div>
      </div>
      <div className="project-details__img-wrap">
        <div
          className="project-details__img"
          style={{
            height: "100vh",
            width: "100%",
            backgroundImage: `url(..${imgPrev})`,
            backgroundSize: "cover",
            //  backgroundSize: fullWidth ? "cover" : "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>

      {!sizeMob ? (
        <div className="mouse" onClick={handleClickScroll}></div>
      ) : (
        <div className="touch-icon-mob swipe">SWIPE LEFT FOR MORE</div>
      )}
    </motion.div>
  );
};

export default ProjectDetailsHero;
