import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const NavItem = ({ link, text, toggleMenu, idx, open }) => {
  return (
    open && (
      <motion.li onClick={toggleMenu}>
        <motion.div
          initial={{ opacity: 0, translateY: -100 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ duration: 0.2, delay: idx * 0.1 }}
        >
          <NavLink to={link}>{text}</NavLink>
        </motion.div>
      </motion.li>
    )
  );
};

export default NavItem;
