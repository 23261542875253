import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Projects from "./pages/projects/Projects";
import Contacts from "./pages/contacts/Contacts";
import ProjectDetails from "./pages/projects/ProjectDetails";
import About from "./pages/about/About";
import { AnimatePresence } from "framer-motion";
import Gallery from "./pages/gallery/Gallery";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/project/:urlName" element={<ProjectDetails />} />
          </Routes>
        </AnimatePresence>
      </BrowserRouter>
    </div>
  );
}

export default App;
