import { motion } from "framer-motion";

const transition = (OgComponent, delay) => {
  return () => (
    <>
      <OgComponent />

      <motion.div
        className="slide-out"
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0 }}
        exit={{ scaleX: 1 }}
        transition={{ duration: 1, ease: [1.22, 0.72, 0.42, 1], delay: delay }}
      />
    </>
  );
};

export default transition;
