import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./nav.css";
import Navigation from "./Navigation";

const Nav = ({ position }) => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
    window.scrollTo(0, 0);
  };

  let navLogoPosition = !open
    ? {
        left: position.left,
        transform: position.transform,
      }
    : {
        left: "30px",
        transform: "translateX(0)",
      };

  return (
    <div className="nav">
      <div className="nav-wrap container">
        <span
          className="logo"
          style={{
            left: navLogoPosition.left,
            transform: navLogoPosition.transform,
          }}
        >
          <NavLink to="/" onClick={() => setOpen(false)}>
            <h1>WEDLIFER</h1>
            <span>studio</span>
          </NavLink>
        </span>
        <div
          className={open ? "nav-burger active" : "nav-burger"}
          onClick={toggleMenu}
        >
          <div className="icon rotate-mob__right">
            {!open ? "MENU" : "CLOSE"}
          </div>
        </div>

        <Navigation open={open} toggleMenu={toggleMenu} />
      </div>
    </div>
  );
};

export default Nav;
