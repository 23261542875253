import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "./loader.css";
import { imagesLoader } from "./config";
import { TypeAnimation } from "react-type-animation";

const Loader1 = () => {
  const [src, setSrc] = useState("");
  const [endAnimImg, setEndAnimImg] = useState(false);
  const [endAnim, setEndAnim] = useState(false);

  let timeout = null;
  let time = 100;
  let i = 0;

  const titleVariant = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  let len = imagesLoader.length;
  let loadCounter = 0;
  let loadCounterImg = 0;
  function change() {
    time += 10;
    setSrc(imagesLoader[i].src);

    if (imagesLoader[++i]) {
      timeout = setTimeout(change, time);
      loadCounterImg++;

      if (loadCounterImg + 1 === len) {
        sessionStorage.setItem("loader", false);
        console.log("anim completed");
        setTimeout(() => {
          setEndAnimImg(true);
        }, 2000);
        setTimeout(() => {
          setEndAnim(true);
        }, 7000);
      }
    }
  }

  let newImagesLoader = imagesLoader.map((el) => {
    return el.src;
  });

  useEffect(() => {
    if (!window.sessionStorage.getItem("loader")) {
      if (document.readyState === "complete") {
        // setTimeout(() => {
        //   change();
        // }, 1000);
        let image = document.querySelectorAll(".img-loader");

        image.forEach((img) => {
          let load = img.complete;
          console.log("load", load);

          loadCounter++;
          if (loadCounter === len) {
            setTimeout(() => {
              change();
            }, 1000);
          }
        });
        console.log("readySate");
      } else {
        window.addEventListener("load", (event) => {
          let image = document.querySelectorAll(".img-loader");

          image.forEach((img) => {
            let load = img.complete;
            console.log("load", load);

            loadCounter++;
            if (loadCounter === len) {
              setTimeout(() => {
                change();
              }, 1000);
            }
          });
        });
        console.log("event here");
      }
    }
  }, []);
  return (
    <motion.div className={`${endAnim ? "hidden-anim" : ""}`}>
      <div className="loader">
        {imagesLoader.map((el, i) => (
          <img
            src={el.src}
            alt=""
            className="img-loader"
            style={{ display: "none" }}
            key={i}
          />
        ))}
        <>
          <motion.span
            className={`${
              endAnimImg ? "logo-loader hidden-logo-loader" : "logo-loader"
            }`}
          >
            <motion.h1
              variants={titleVariant}
              initial="initial"
              animate="animate"
            >
              WEDLIFER
            </motion.h1>

            <span className="subtitle">studio</span>
          </motion.span>

          <motion.div
            className={`${
              endAnimImg ? "loader-img hidden-anim-img" : "loader-img"
            }`}
          >
            <img src={src} alt="" />
          </motion.div>
          <motion.div>
            <motion.div>
              <motion.h2
                className={`${
                  endAnimImg ? "logo-finish anim-logo-finish" : "logo-finish"
                }`}
              >
                {endAnimImg && (
                  <TypeAnimation
                    sequence={["LET’S CREATE AMAZING WEDDINGS TOGETHER", 1000]}
                    wrapper="span"
                    speed={150}
                    repeat={1}
                  />
                )}
              </motion.h2>
            </motion.div>
          </motion.div>
        </>
      </div>
    </motion.div>
  );
};

export default Loader1;
