import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { projects } from "./config";

import { useScroll } from "framer-motion";
import useWindowDimensions from "../../components/hooks/useWindowDimensions";
import transition from "../../transition";
import Nav from "../../components/nav/Nav";
import SwiperMob from "../../components/swiper/SwiperMob";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import ParallaxText from "../../components/parallaxText/ParallaxText";
import ProjectDetailsHero from "./ProjectDetailsHero";
import { AsyncImage } from "loadable-image";
// import aspectRatioImg from "../../components/functions/aspectRatioImg";
import LoaderLight from "../../components/loaderLight/LoaderLight";
import calculateAspectRatio from "calculate-aspect-ratio";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import InfiniteScroll from "react-infinite-scroll-component";

const ProjectDetails = () => {
  const navigate = useNavigate();
  const { urlName } = useParams();
  const { height, width } = useWindowDimensions();
  const ref = useRef(null);
  let isItems = projects.find((item) => item.urlName == urlName);
  let { title, imgListSrc, location, imgPrev } = isItems;

  const sizeMob = width < 1024;

  let [fullWidth, setFullWidth] = useState(false);
  const toggleFullWidth = () => setFullWidth((value) => !value);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["1 0", "1.1 0.9"],
  });

  const [newProjects, setnewProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scroll(0, 0);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleClickScroll = () => {
    const element = document.getElementById("projects-details");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navLogoPosition = {
    left: "50%",
    transform: "translateX(-50%)",
  };

  const [items, setItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const itemsPerPage = 4; // Adjust based on your needs
  useEffect(() => {
    const fetchData = () => {
      const response = imgListSrc;
      setItems(response);
      setDisplayedItems(response.slice(0, itemsPerPage));
    };
    fetchData();
  }, []);
  const fetchMoreData = () => {
    if (displayedItems.length >= items.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setDisplayedItems((prevItems) => [
        ...prevItems,
        ...items.slice(prevItems.length, prevItems.length + itemsPerPage),
      ]);
      setPage((prevPage) => prevPage + 1);
    }, 500);
  };

  return (
    <div>
      {/* {isLoading && <LoaderLight />} */}
      <Nav position={navLogoPosition} />

      <div className="project-details">
        {!sizeMob && (
          <ProjectDetailsHero
            refScrl={ref}
            scrollYProgress={scrollYProgress}
            location={location}
            title={title}
            imgPrev={imgPrev}
            handleClickScroll={handleClickScroll}
            sizeMob={sizeMob}
            fullWidth={fullWidth}
          />
        )}

        <div id="projects-details">
          {!sizeMob ? (
            <>
              <section>
                <ParallaxText baseVelocity={-1}>
                  Wedlifer studio Wedlifer studio Wedlifer studio
                </ParallaxText>
                <ParallaxText baseVelocity={1}>
                  FEELING LOVE PASSION AESTHETIC EMOTIONS MOMENTS STYLE
                </ParallaxText>
              </section>
              <InfiniteScroll
                hasMore={true}
                dataLength={displayedItems.length}
                next={fetchMoreData}
              >
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 1024: 1, 1025: 3, 1100: 4 }}
                >
                  <Masonry className="masonry-wrap" gutter="20px">
                    {displayedItems &&
                      displayedItems.map((img) => (
                        <div key={img.id} className="masonry-item">
                          <LazyLoadImage
                            src={img.src}
                            alt=""
                            effect="blur"
                            threshold={100}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                      ))}
                  </Masonry>
                </ResponsiveMasonry>
              </InfiniteScroll>
            </>
          ) : (
            <SwiperMob
              imgListSrc={imgListSrc}
              location={location}
              title={title}
              imgPrev={imgPrev}
              sizeMob={sizeMob}
              fullWidth={fullWidth}
              toggleFullWidth={toggleFullWidth}
            />
          )}
        </div>
      </div>
      <div className="back rotate-mob__left" onClick={() => navigate(-1)}>
        BACK
      </div>
    </div>
  );
};

export default transition(ProjectDetails, 0);
